<template>
  <b-card>
    <b-row>
      <b-col md="12">
        <h4>Gestión de usuarios</h4>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col md="6">
        <b-form @submit.prevent="onSubmit">

          <b-input-group>
            <b-input-group-append>
              <b-button
                v-if="hasContent"
                id="btnClearSearchUser"
                v-b-tooltip.hover="'Limpiar filtro'"
                class="closeStyle"
                size="sm"
                @click="()=>clearSearchUser()"
              >
                <font-awesome-icon icon="fa-solid fa-eraser" />
              </b-button>
            </b-input-group-append>
            <b-form-input
              id="txtFiltro"
              v-model="filtro"
              :disabled="isBusyBuscando"
              autocomplete="off"
              placeholder=" Búsqueda por ( Nombre - Apellido - C.U.I.L. - Correo )"
            />
            <!--<div class="align-self-end ml-auto">-->
            <b-input-group-prepend>
              <b-button
                id="btnSearchUsuario"
                v-b-tooltip.hover
                title="Buscar usuario"
                size="sm"
                variant="primary"
                :disabled="isBusyBuscando"
                @click="buscarUsuarios()"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-prepend>

          </b-input-group>
        </b-form>
      </b-col>
      <b-col
        md="6"
        class="text-right"
      >
        <b-button
          v-if="permisosIns.includes(nameModulo)"
          id="btnAddUsuario"
          v-b-tooltip.hover
          squared
          style="float: right;"
          size="sm"
          title="Nuevo usuario"
          variant="success"
          :disabled="isBusyBuscando"
          @click="()=>addUsuario()"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col md="12">
        <b-table
          id="tblUsusario"
          :items="tableDataUsuario"
          :busy="isBusyBuscando"
          :fields="fieldsUsuario"
          outlined
          show-empty
          small
          hover
          sticky-header="70vh"
          :no-border-collapse="true"
          empty-text="No se encontraron resultados para esta búsqueda"
          @row-dblclicked="dobleClik"
        >
          <template v-slot:table-busy>
            <div class="text-center">
              <b-spinner />
            </div>
          </template>
          <template #cell(estado)="row">
            <small>
              {{ row.item.estado }}
            </small>

          </template>
          <template #cell(actions)="row">
            <div
              class="btn-group"
              role="group"
              aria-label="Basic example"
            >
              <b-button
                v-if="permisosVer.includes(nameModulo)"
                v-b-tooltip.hover.righttop="'Ver usuario'"
                size="sm"
                @click="viewUsuario(row.item, row.index, $event.target)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-if="permisosUpd.includes(nameModulo)"
                v-b-tooltip.hover.righttop="'Editar usuario'"
                size="sm"
                @click="editUsuario(row.item, row.index, $event.target)"
              >
                <!--<feather-icon icon="EyeIcon" />-->
                <font-awesome-icon icon="fa-regular fa-pen-to-square" />
              </b-button>
              <!--<b-button
                  v-if="permisosDel.includes(nameModulo) && row.item.estado === 'Activo'"
                  v-b-tooltip.hover.right="'Desactivar usuario'"
                  variant="danger"
                  :disabled="row.item.isProcesando"
                  size="sm"
                  @click="desactivarUsuario(row.item, row.index, $event.target)"
                >
                  <font-awesome-icon
                    v-if="!row.item.isProcesando"
                    icon="fa-solid fa-ban"
                  />
                  <span
                    v-if="row.item.isProcesando"
                    class="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  />
                </b-button>
                <b-button
                  v-if="permisosDel.includes(nameModulo) && row.item.estado !== 'Activo'"
                  v-b-tooltip.hover.left
                  variant="primary"
                  :disabled="row.item.isProcesando"
                  size="sm"
                  title="Reactivar usuario"
                  @click="activarUsuario(row.item, row.index, $event.target)"
                >
                  <font-awesome-icon
                    v-if="!row.item.isProcesando"
                    icon="fa-solid fa-play"
                  />
                  <span
                    v-if="row.item.isProcesando"
                    class="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  />
                </b-button>-->
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <div class="align-self-end ml-auto">
        <b-col md="12">
          <b-input-group>

            <b-input-group-append>
              <b-form-select
                v-model="pageUsuario.size"
                :clearable="false"
                label="title"
                size="sm"
                :options="optionsPaginationSize"
                :disabled="isBusyBuscando"
                title="Seleccione la cantidad de resultados por página"
                @change="buscarUsuarios()"
              />
            </b-input-group-append>

            <b-pagination
              id="pgnUsuario"
              v-model="currentPage"
              :total-rows="pageTotalElements"
              :per-page="pageSize"
              :disabled="isBusyBuscando"
              @change="changePaginate"
            />
          </b-input-group>
        </b-col>
      </div>
    </b-row>
  </b-card>
</template>
<script>
// import BCardCode from '@core/components/b-card-code'
import { mapGetters } from 'vuex'
import { usuariosResource } from '@/services/usuariosResource'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BCardCode,
  },
  data() {
    return {
      useLocalStorageLastSearch: true,
      cargando: false,
      pageTotalElements: 0,
      currentPage: 1,
      pageSize: 0,
      filtro: null,
      pageUsuario: {
        page: 0,
        size: 7,
        sort: 'apellido,asc',
      },
      nameModulo: 'ABM_USUARIO',
      isBusyBuscando: false,
      isActivando: false,
      isDesactivando: false,
      tableDataUsuario: [],
      optionsPaginationSize: [5, 7, 10, 15, 20, 25, 30],
      fieldsUsuario: [
        {
          key: 'apellido',
          label: 'Apellido',
          thStyle: { width: '25%' },
        },
        {
          key: 'nombre', label: 'Nombre',
        },
        {
          key: 'cuil', label: 'C.U.I.L.', thStyle: { width: '10%' },
        },
        {
          key: 'email', label: 'Correo',
        },
        {
          key: 'estado', label: 'estado', thStyle: { width: '6%' },
        },
        { key: 'actions', label: 'Acciones', thStyle: { width: '10%' } },
      ],
    }
  },

  computed: {
    hasContent() {
      return (this.filtro != null && this.filtro.length > 0)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    this.veryAndSetLastPath()
    if (this.useLocalStorageLastSearch) { this.applyLastSearch() }
    this.getUsuarioByFilter()
  },
  methods: {
    veryAndSetLastPath() {
      const lastPath = localStorage.getItem('lastPath')
      if (lastPath !== null && !lastPath.includes('usuario')) {
        localStorage.setItem('abm_lastSearch', '')
      }
      localStorage.setItem('lastPath', this.$route.path)
    },
    applyLastSearch() {
      if (localStorage.getItem('abm_lastSearch')) {
        this.filtro = localStorage.getItem('abm_lastSearch')
      } else {
        this.filtro = null
      }
      if (localStorage.getItem('usuario_page_size_lastset')) {
        this.pageUsuario.size = localStorage.getItem('usuario_page_size_lastset')
      } else {
        this.pageUsuario.size = 7
      }
    },
    dobleClik(record) {
      this.$router.push(`/usuarios/view/${record.id}`)
    },
    onSubmit() {
      if (!this.isBusyBuscando) {
        this.buscarUsuarios()
      }
    },
    clearSearchUser() {
      this.filtro = ''
      this.buscarUsuarios()
    },
    viewUsuario(item) {
      this.$router.push(`/usuarios/view/${item.id}`)
    },
    editUsuario(item) {
      this.$router.push(`/usuarios/edit/${item.id}`)
    },
    addUsuario() {
      this.$router.push('/usuarios/new')
    },
    buscarUsuarios() {
      if (this.useLocalStorageLastSearch) {
        if (this.filtro) {
          localStorage.setItem('abm_lastSearch', this.filtro)
        } else {
          localStorage.setItem('abm_lastSearch', '')
        }
        localStorage.setItem('usuario_page_size_lastset', this.pageUsuario.size)
      }
      this.currentPage = 1
      this.pageUsuario.page = 0
      this.getUsuarioByFilter()
    },
    getUsuarioByFilter() {
      this.isBusyBuscando = true
      usuariosResource().getByFilterUsuarioSpecs(this.filtro, this.pageUsuario)
        .then(resultGetByUsuarioByFilter => {
          this.tableDataUsuario = resultGetByUsuarioByFilter.content
          this.pageTotalElements = resultGetByUsuarioByFilter.totalElements
          this.pageNumber = resultGetByUsuarioByFilter.number
          this.pageSize = resultGetByUsuarioByFilter.size
        }).catch(error => {
          console.error('errors:', error)
          this.tableDataUsuario = []
        })
        .finally(() => {
          this.isBusyBuscando = false
        })
    },
    desactivarUsuario(item) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea desactivar el usuario ${item.apellido} ${item.nombre}?</p>`
      this.$swal({
        title: 'Desactivación',
        // text: `¿Desea desactivar el usuario ${item.apellido} ${item.nombre}?`,
        html: htmlText,
        icon: 'warning',
        background: this.swalConfig.background,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.tableDataUsuario.find(usuario => usuario.id === item.id).isProcesando = true
          usuariosResource().desactivarUsuario(item.id)
            .then(resultsDeleteDeleteUsuarios => {
              // this.getUsuarioByFilter(this.filterUsuario, this.pageUsuario)
              this.tableDataUsuario.find(usuario => usuario.id === resultsDeleteDeleteUsuarios).estado = 'Inactivo'
            }).catch(error => {
              console.error(error)
            })
            .finally(() => {
              this.tableDataUsuario.find(usuario => usuario.id === item.id).isProcesando = false
            })
        }
      })
    },
    activarUsuario(item) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea reactivar el usuario ${item.apellido} ${item.nombre}?</p>`
      this.$swal({
        title: 'Reactivación',
        // text: `¿Desea reactivar el usuario ${item.apellido} ${item.nombre}?`,
        html: htmlText,
        icon: 'warning',
        background: this.swalConfig.background,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.tableDataUsuario.find(usuario => usuario.id === item.id).isProcesando = true
          usuariosResource().activarUsuario(item.id)
            .then(resultsReactivarUsuarios => {
              this.tableDataUsuario.find(usuario => usuario.id === resultsReactivarUsuarios).estado = 'Activo'
            })
            .catch(error => {
              console.error(error)
            })
            .finally(() => {
              this.tableDataUsuario.find(usuario => usuario.id === item.id).isProcesando = false
            })
        }
      })
    },
    changePaginate(item) {
      this.pageUsuario.page = item - 1
      this.isBusyBuscando = true
      this.getUsuarioByFilter()
    },
  },
}
</script>
<style>
.closeStyle {
  border-color: #cad1d7;
}
.text-red {
  color: red;
}
</style>
